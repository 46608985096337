import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { RouteType, useVerifyAuthSession } from '../hooks/useVerifyAuthSession'

const IndexPage: NextPage = () => {
  useVerifyAuthSession(RouteType.PRIVATE)

  const router = useRouter()

  useEffect(() => {
    router.replace('/auth/sign-in')
  }, [router])

  return null
}

export default IndexPage
