import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { AuthState, useAuthContext } from '../context/AuthContext'

export enum RouteType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

const AUTH_PAGE = '/auth/sign-in'
// const VERIFY_PAGE = '/auth/verify'

const useVerifyAuthSession = (routeType: RouteType = RouteType.PRIVATE) => {
  const router = useRouter()
  const { state, appUser, firebaseUser } = useAuthContext()

  useEffect(() => {
    // wait for loading
    if (firebaseUser === undefined) {
      return
    }

    if (state === null) {
      return
    }

    // private routes
    if (routeType === RouteType.PRIVATE && state === AuthState.SIGN_OUT) {
      router.push(AUTH_PAGE)
      return
    }
    // not signed in
    if (routeType === RouteType.PRIVATE && !appUser) {
      router.replace(AUTH_PAGE)
      return
    }
    // public routes
    if (
      routeType === RouteType.PUBLIC &&
      state === AuthState.SIGN_IN &&
      appUser
    ) {
      if (['ADMIN', 'OPERATOR', 'SALES'].includes(appUser.role)) {
        router.replace('/app/dashboard')
      } else if (
        ['CLIENT_OWNER', 'CLIENT_MEMBER', 'BRAND_AUDITOR'].includes(
          appUser.role,
        )
      ) {
        router.replace('/app/campaign')
      } else if (['WAREHOUSE'].includes(appUser.role)) {
        router.replace('/app/warehouse/stock')
      } else if (['LEGAL'].includes(appUser.role)) {
        router.replace('/app/pre-task')
      } else {
        router.replace('/app')
      }
      // return
    }
    // // if email is not verified
    // if (
    //   firebaseUser &&
    //   !firebaseUser.emailVerified &&
    //   !router.pathname.includes(VERIFY_PAGE)
    // ) {
    //   router.push(VERIFY_PAGE)
    // }
  }, [appUser, firebaseUser, routeType, router, state])

  return [state]
}

export { useVerifyAuthSession }
